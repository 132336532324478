<template>
  <div class="px-3 py-2 w-100 question-editor">
    <div class="d-flex align-items-center mb-2">
      <strong class="mr-auto">{{ position + 1 }}°</strong>
      <b-button-close
        @click="$emit('close-question-editor', localQuestion.id)"
      />
    </div>
    <b-input-group class="question">
      <span
        class="quiz-picture"
        @click="showPreview(getCurrentUrl(localQuestion))"
      >
        <b-img-lazy
          v-if="getCurrentUrl(localQuestion)"
          :src="getCurrentUrl(localQuestion)"
          class="preview-image"
        ></b-img-lazy>
        <b-icon-x-circle v-else />
      </span>
      <b-form-input v-model="localQuestion.question[question_lang]">
      </b-form-input>
      <b-input-group-append>
        <div
          class="tools-box align-self-stretch ml-auto d-flex align-items-center"
        >
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="learning-photo-icon"
            no-caret
          >
            <template #button-content>
              <b-icon icon="camera-fill"></b-icon>
            </template>

            <b-form-file
              class="picture-uploader dropdown-item"
              v-model="file"
              accept="image/jpeg, image/png, image/gif"
              @input="createTmpUrl(localQuestion)"
              ref="file-input-q"
            >
              <template #placeholder>
                <b-dropdown-item>Select picture</b-dropdown-item>
              </template>
              <template #file-name>
                <b-dropdown-item>Select picture</b-dropdown-item>
              </template>
            </b-form-file>
            <b-icon
              v-if="loadingFile"
              class="loading-file"
              icon="arrow-clockwise"
              animation="spin"
              font-scale="2"
            ></b-icon>
            <b-dropdown-item
              :disabled="!localQuestion.fileUrl"
              @click="removeCurrentUpload(localQuestion)"
              >Remove new upload</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="removePicture(localQuestion)"
              >Remove picture</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-input-group-append>
      <LangOptions v-model="question_lang" :options="languages" class="lang" />
    </b-input-group>

    <div v-if="!isChecklist" class="pl-4">
      <b-input-group
        v-for="answer in localQuestion.answers"
        :key="answer.id"
        class="answer"
      >
        <span class="quiz-picture" @click="showPreview(getCurrentUrl(answer))">
          <b-img-lazy
            v-if="getCurrentUrl(answer)"
            :src="getCurrentUrl(answer)"
            class="preview-image"
          ></b-img-lazy>
          <b-icon-x-circle v-else />
        </span>
        <b-form-input
          v-model="answer.answer[answers_lang[answer.id]]"
          @change="confirmQuestion()"
        ></b-form-input>
        <b-input-group-append>
          <div
            class="
              tools-box
              py-0
              align-self-stretch
              ml-auto
              d-flex
              align-items-center
            "
          >
            <b-form-checkbox v-model="answer.isCorrect" switch size="sm" />
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="learning-photo-icon"
              no-caret
            >
              <template #button-content>
                <b-icon icon="camera-fill"></b-icon>
              </template>

              <b-form-file
                class="picture-uploader dropdown-item"
                v-model="file"
                accept="image/jpeg, image/png, image/gif"
                @input="createTmpUrl(answer)"
                ref="file-input"
              >
                <template #placeholder>
                  <b-dropdown-item>Select picture</b-dropdown-item>
                </template>
                <template #file-name>
                  <b-dropdown-item>Select picture</b-dropdown-item>
                </template>
              </b-form-file>
              <b-icon
                v-if="loadingFile"
                class="loading-file"
                icon="arrow-clockwise"
                animation="spin"
                font-scale="2"
              ></b-icon>
              <b-dropdown-item
                :disabled="!answer.fileUrl"
                @click="removeCurrentUpload(answer)"
                >Remove new upload</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="removePicture(answer)"
                >Remove picture</b-dropdown-item
              >
            </b-dropdown>
            <b-icon
              icon="trash-fill"
              @click="deleteAnswer(answer.id)"
              class="pointer"
            ></b-icon>
          </div>
        </b-input-group-append>
        <LangOptions
          v-model="answers_lang[answer.id]"
          :options="languages"
          class="lang"
        />
      </b-input-group>
    </div>

    <div v-if="!isChecklist" class="d-flex justify-content-center mt-2 mb-2">
      <b-button
        squared
        size="lg"
        class="button-dashed big-button mt-2 mr-5"
        @click="addAnswer"
        >Add answer</b-button
      >
    </div>
    <b-modal
      v-model="showPreviewModal"
      hide-header
      hide-footer
      modal-class="preview-modal"
    >
      <b-icon-x
        class="close-button pointer"
        @click="showPreviewModal = false"
      ></b-icon-x>
      <b-img :src="previewUrl" fluid />
    </b-modal>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import AxiosService from "../../axiosServices/AxiosService";

export default {
  // props: ["question", "position", "default_lang", "languages"],
  props: {
    question: {
      type: Object,
    },
    default_lang: {
      type: Object,
    },
    languages: {
      type: Array,
    },
    position: {
      type: Number,
    },
    isChecklist: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      localQuestion: JSON.parse(JSON.stringify(this.question)),
      showPreviewModal: false,
      previewUrl: "",
      file: null,
      loadingFile: false,
      question_lang: null,
      answers_lang: {},
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("User");
    this.question_lang = this.default_lang.value;
    this.localQuestion.answers.forEach((x) =>
      this.$set(this.answers_lang, x.id, this.default_lang.value)
    );
  },
  methods: {
    addAnswer() {
      let newAnswer = {
        id: uuid(),
        answer: {},
        isCorrect: false,
      };
      this.localQuestion.answers.push(newAnswer);
      this.$set(this.answers_lang, newAnswer.id, this.default_lang.value);
      this.confirmQuestion();
    },
    deleteAnswer(id) {
      this.localQuestion.answers = this.localQuestion.answers.filter(
        (x) => x.id !== id
      );
      delete this.answers_lang[id];
      this.confirmQuestion();
    },
    showPreview(url) {
      if (url) {
        this.showPreviewModal = true;
        this.previewUrl = url;
      }
    },
    async createTmpUrl(item) {
      if (this.file) {
        this.loadingFile = true;
        // const fileUrl = URL.createObjectURL(this.file) || "";
        if (this.file.size > 3 * 1024 * 1024) {
          alert("I file non possono superare il massimo di 3MB");
          return;
        }
        const formData = new FormData();
        formData.append("pic", this.file);
        let fileName = await this.service
          .uploadFile(formData)
          .then((res) => res.data)
          .finally(() => {
            this.loadingFile = false;
          });
        let fileUrl = this.$driveAddress + fileName;
        item.fileUrl = fileUrl.replaceAll(" ", "%20");

        this.$set(item, "fileUrl", fileUrl);
        this.$refs["file-input-q"].reset();
        this.$refs["file-input"].forEach((x) => x.reset());
        this.confirmQuestion();
      }
    },
    getCurrentUrl(item) {
      return item.fileUrl ? item.fileUrl : item.image_url;
    },
    removeCurrentUpload(item) {
      this.$set(item, "fileUrl", "");
    },
    removePicture(item) {
      this.$set(item, "fileUrl", "");
      this.$set(item, "image_url", "");
    },
    confirmQuestion() {
      const validationObject = this.$validate(
        "learning_quiz",
        this.localQuestion
      );
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      this.$emit("update-question", this.localQuestion);
    },
  },
};
</script>
