<template>
  <div>
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="item.title[title_lang]" />
      <LangOptions v-model="title_lang" :options="languages" />
    </b-form-group>
    <b-form-group label="Description" label-for="description-en">
      <b-form-textarea v-model="item.description[description_lang]" rows="3" />
      <LangOptions v-model="description_lang" :options="languages" />
    </b-form-group>

    <!-- <b-form-group label="Chapter Cover Image">
      <b-form-file v-model="coverImage" @change="handleCoverImageChange" />

      <div v-if="coverImage" class="mt-2">
        <img
          :src="coverImagePreview"
          alt="Cover Image Preview"
          style="max-width: 100%; max-height: 200px;"
        />
      </div>
    </b-form-group> -->

    <span class=" font-bold  pt-5">Immagine Capitolo</span>

    <b-form-file
      v-model="coverImage"
      @input="updateCoverImage"
      accept="image/jpeg, image/png, image/gif"
    ></b-form-file>

    <div
      v-if="item.immagine_capitolo"
      class="w-100 mt-4 mb-4 d-flex justify-content-center align-items-center"
    >
      <img :src="item.immagine_capitolo" style="width: auto; height: 400px;" />
    </div>

    <span class=" font-bold  pt-5">Documenti</span>

    <files-upload-manager v-model="item.documents" />

    <div class="mt-5 mb-5">
      <b-button
        v-if="isLoading"
        squared
        variant="primary"
        size="lg"
        class="big-button mr-2"
        disabled
        ><b-spinner small type="grow"></b-spinner> Loading...</b-button
      >
      <b-button
        v-else
        squared
        variant="primary"
        size="lg"
        class="big-button mr-2"
        @click="saveItem"
        >Save Details</b-button
      >

      <b-button
        v-if="isLoading"
        squared
        variant="secondary"
        size="lg"
        class="big-button"
        disabled
      >
        <b-spinner small type="grow"></b-spinner> Loading...
      </b-button>
      <b-button
        v-else
        squared
        variant="secondary"
        size="lg"
        class="big-button"
        @click="saveItem({ attivo: true })"
        >Save and Activate
      </b-button>
    </div>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import FilesUploadManager from "../FilesUploadManager.vue";
export default {
  components: {
    FilesUploadManager,
  },
  props: ["details", "isLoading", "languages", "default_lang"],
  data() {
    return {
      title_lang: null,
      description_lang: null,
      item: {},
      coverImage: null, // Property to store the cover image file
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("Boh");
    this.title_lang = this.default_lang.value || this.default_lang;
    this.description_lang = this.default_lang.value || this.default_lang;
    this.item = this.details;
  },
  methods: {
    updateCoverImage() {
      this.item.immagine_capitolo = URL.createObjectURL(this.coverImage) || "";
    },
    async saveItem(options = { attivo: false }) {
      if (this.coverImage) {
        // if (this.coverImage.size > 3 * 1024 * 1024) {
        //   alert("I file non possono superare il massimo di 3MB");
        //   return;
        // }
        const formData = new FormData();
        formData.append("pic", this.coverImage);
        let imageName = await this.service
          .uploadFile(formData)
          .then((res) => res.data);

        let imageUrl = this.$driveAddress + imageName;
        this.item.immagine_capitolo = imageUrl.replaceAll(" ", "%20");
      }

      if (options.attivo) this.item.attivo = true;

      // Emit the updated item to parent component
      this.$emit("save-details", this.item);
    },

    handleCoverImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.coverImage = file;
        this.generateCoverImagePreview(file);
      } else {
        this.coverImage = null;
        this.coverImagePreview = null;
      }
    },
    generateCoverImagePreview(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.coverImagePreview = event.target.result;
        this.$emit("update-cover-image", this.coverImagePreview);
      };
      reader.readAsDataURL(file);
    },
  },
  watch: {
    details: {
      handler(newVal) {
        this.item = newVal;
      },
      deep: true,
    },
  },
  computed: {
    coverImagePreview() {
      return this.coverImage ? URL.createObjectURL(this.coverImage) : null;
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles for image preview */
</style>
